<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>
<style lang="scss">
@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css);

input {
  border-color: #7E60AB !important;
  border: 2px solid #7E60AB !important;
  background-color: transparent!important;
  box-shadow: none !important;
}
</style>