<template>
  <div style="height: 100%; min-height: 100vh; background-image: linear-gradient(0deg, #ffffff 0%, #d9c7f2 100%);">
    <template v-if="!showConditions">
      <template v-if="!responseApi">
        <div class="px-4 pt-4 text-center d-flex flex-column align-items-center justify-content-center">
          <img src="../assets/img/logo.svg" style="max-width: 180px;"/>
          <h3 class="pt-4 mb-3 fw-bold odpurple">Teilnehmen und gewinnen</h3>
          <p class="small m-0" style="max-width: 414px;">Nimm jetzt an unserem EUHA-Gewinnspiel teil und sichere die Chance auf eine odWeb.tv Digital Marketing Suite im Wert von 2.146,- €! Außerdem warten viele weitere Preise auf dich.</p>
        </div>

        <form-wizard title="" stepSize="xs" nextButtonText="Weiter" backButtonText="Zurück" ref="wizard"
                     finishButtonText="Teilnehmen" subtitle="">
          <tab-content>
            <div class="row mx-0 p-0">
              <div class="col-12 p-0">
                <input type="text" class="form-control form-control-lg" required="" placeholder="Vorname" id="forename"
                       v-model="data.forename"/>
              </div>
              <div class="col-12 p-0 mt-2">
                <input type="text" class="form-control form-control-lg" required="" placeholder="Nachname" id="lastname"
                       v-model="data.lastname"/>
              </div>
              <div class="col-12 p-0 mt-2 mb-2">
                <input type="text" class="form-control form-control-lg" required="" placeholder="Firma" id="company"
                       v-model="data.company"/>
              </div>
            </div>
          </tab-content>
          <tab-content>

<!--                        <div v-show="!data.email.valid" class="alert alert-danger" role="alert">-->
<!--                          Bitte gib eine gültige E-Mail an.-->
<!--                        </div>-->

            <div class="row mx-0 p-0">
              <div class="col-12 p-0">
                <input type="email" name="email" id="email" required="" placeholder="E-Mail"
                       class="form-control form-control-lg" v-model="data.email.value"/>
              </div>

              <div class="col-12 p-0 mt-2">
                <input type="text" class="form-control form-control-lg" required="" placeholder="Telefon" id="phone"
                       v-model="data.phone"/>
              </div>

              <div class="col-12 p-0 mt-3 mb-4 d-flex align-items-center">
                <input type="checkbox" id="checkbox-con" required v-model="data.conditions"/>
                <label for="checkbox-con" class="small" style="margin-left: 4px;">Teilnahmebedingungen
                  akzeptieren</label>
              </div>
            </div>
          </tab-content>
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
              <wizard-button class="flex-grow-1 btn-four-outline" v-if="props.activeTabIndex > 0"
                             @click.native="props.prevTab()" :style="props.fillButtonStyle">Zurück
              </wizard-button>
            </div>
            <div class="wizard-footer-right">
              <wizard-button v-if="!props.isLastStep" :disabled="data.forename && data.lastname && data.company === ''" @click.native="props.nextTab()"
                             class="wizard-footer-right btn-four-outline" :style="props.fillButtonStyle">Weiter
              </wizard-button>
              <wizard-button :disabled="buttonDisable" v-else @click.native="onComplete"
                             class="wizard-footer-right btn-four" :style="props.fillButtonStyle">
                {{ props.isLastStep ? 'Teilnehmen' : 'Weiter' }}
              </wizard-button>
            </div>
          </template>
        </form-wizard>


        <div @click="setCondition"
             class="align-items-center conditions d-flex justify-content-center bottom-0 position-fixed">
          <span>Teilnahmebedindungen</span>
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center pt-4">
          <img src="../assets/img/logo.svg" style="max-width: 180px;"/>
        </div>

        <div class="d-flex flex-column align-items-center justify-content-center" style="height: 70vh">
          <img src="../assets/img/smile_finish.svg" style="max-width: 180px;"/>
          <div class="px-4 text-center">
            <h1 class="mb-3 fw-bold odpurple">Viel Glück!</h1>
            <p class="small m-0" style="max-width: 414px;">Vielen Dank für deine Teilnahme an unserem Gewinnspiel. Wir drücken dir die Daumen!</p>
          </div>
        </div>
      </template>
    </template>

    <template v-else>
      <div class="px-4">
        <h3 class="pt-4 mb-3 fw-bold odpurple">Teilnahmebedinungen</h3>

        <p class="small odpurple">
          Die Teilnahme am Gewinnspiel der opta data digital communication GmbH, nachfolgend Veranstalter genannt, ist
          kostenlos und richtet sich ausschließlich nach diesen Teilnahmebedingungen.
        </p>

        <h6 class="pt-2 mb-1 fw-bold odpurple">Ablauf des Gewinnspiels</h6>
        <p class="small odpurple">
          Die Dauer des Gewinnspiels erstreckt sich vom 15.09.2021, 09:00 Uhr, bis zum 17.09.2021, 20:00 Uhr. Innerhalb
          dieses Zeitraums erhalten Nutzer online die Möglichkeit, am Gewinnspiel teilzunehmen.
        </p>

        <h6 class="pt-2 mb-1 fw-bold odpurple">Teilnahme</h6>
        <p class="small odpurple">
          Um am Gewinnspiel teilzunehmen, ist ein Ausfüllen und Absenden des angezeigten Teilnahmeformulars notwendig.
          Eine wirksame Teilnahme liegt nur dann vor, wenn sämtliche personenbezogenen Angaben des Teilnehmers
          vollständig und wahrheitsgemäß sind. Die Teilnahme ist nur innerhalb des Teilnahmezeitraums möglich. Nach
          Teilnahmeschluss eingehende Einsendungen werden bei der Auslosung nicht berücksichtigt.
        </p>

        <p class="small odpurple">
          Jeder Teilnehmer darf nur einmal am Gewinnspiel teilnehmen. Die Teilnahme am Gewinnspiel ist kostenlos.
        </p>


        <h6 class="pt-2 mb-1 fw-bold odpurple">Teilnahmeberechtigte</h6>
        <p class="small odpurple">
          Teilnahmeberechtigt sind natürliche Personen, die ihren Wohnsitz in Deutschland und das 18. Lebensjahr
          vollendet haben. Die Teilnahme ist nicht auf Kunden des Veranstalters beschränkt und nicht vom Erwerb einer
          Ware oder Dienstleistung abhängig.

          Nicht teilnahmeberechtigt am Gewinnspiel sind alle an der Konzeption und Umsetzung des Gewinnspiels
          beteiligten Personen und Mitarbeiter des Betreibers sowie ihre Familienmitglieder. Zudem behält sich der
          Betreiber vor, nach eigenem Ermessen Personen von der Teilnahme auszuschließen, wenn berechtigte Gründe
          vorliegen, beispielsweise

          (a) bei Manipulationen im Zusammenhang mit Zugang zum oder Durchführung des Gewinnspiels, (b) bei Verstößen
          gegen diese Teilnahmebedingungen, (c) bei unlauterem Handeln oder (d) bei falschen oder irreführenden Angaben
          im Zusammenhang mit der Teilnahme am Gewinnspiel.
        </p>

        <h6 class="pt-2 mb-1 fw-bold odpurple">Gewinn, Benachrichtigung und Übermittlung des Gewinns</h6>
        <p class="small odpurple">
          Folgende Preise werden vergeben:<br>

          1. Platz: 1 x 2-Jahres-Lizenz odWeb.tv Digital Marketing Suite inkl. Einrichtung & Schulung, 1 x odWeb.tv
          Pro-Player, 1 x iPad + 1 x Bouncepad Bodenständer<br>

          2. Platz: 1 x 2-Jahres-Lizenz odWeb.tv Digital Marketing Suite inkl. Einrichtung & Schulung, 1 x odWeb.tv
          Pro-Player<br>

          3. Platz: 1 x 2-Jahres-Lizenz odWeb.tv Digital Marketing Suite inkl. Einrichtung & Schulung<br>

          4. – 10. Platz: je 1 x 1-Jahres-Lizenz odWeb.tv Digital Signage oder odWeb.tv Surveys – ohne Hardware<br>

          Die Ermittlung der Gewinner erfolgt nach Teilnahmeschluss im Rahmen einer auf dem Zufallsprinzip beruhenden
          Verlosung unter allen Teilnehmern.

          Die Gewinner der Verlosung werden zeitnah über eine gesonderte E-Mail über den Gewinn informiert.

          Die Aushändigung des Gewinns erfolgt ausschließlich an den Gewinner. Ein Umtausch, eine Selbstabholung sowie
          eine Barauszahlung des Gewinns sind nicht möglich.

          Eventuell für den Versand der Gewinne anfallende Kosten übernimmt der Betreiber. Mit der Inanspruchnahme des
          Gewinns verbundene Zusatzkosten gehen zu Lasten des Gewinners. Für eine etwaige Versteuerung des Gewinns ist
          der Gewinner selbst verantwortlich.

          Meldet sich der Gewinner nach zweifacher Aufforderung innerhalb einer Frist von 3 Wochen nicht, kann der
          Gewinn auf einen anderen Teilnehmer übertragen werden.
        </p>

        <p class="small odpurple">
          Der Veranstalter behält sich ausdrücklich vor, das Gewinnspiel ohne vorherige Ankündigung und ohne Mitteilung
          von Gründen zu beenden. Dies gilt insbesondere für jegliche Gründe, die einen planmäßigen Ablauf des
          Gewinnspiels stören oder verhindern würden.
        </p>


        <h6 class="pt-2 mb-1 fw-bold odpurple">Datenschutz</h6>
        <ol class="small odpurple" style="padding-inline-start: 16px;">
          <li>Unser Datenschutzbeauftragter steht Ihnen für Rückfragen bezüglich unseres Datenschutzes gerne zur
            Verfügung.
          </li>
          <br>
          <li>
            Der Veranstalter versichert, dass sämtliche personenbezogenen Daten des Teilnehmers ohne sein Einverständnis
            weder an Dritte weitergegeben noch diesen zur Nutzung überlassen werden. Eine Weitergabe der erhobenen Daten
            zu
            Werbezwecken an Unternehmen der opta data Unternehmensgruppe setzt die erklärte Einwilligung des Teilnehmers
            per Ankreuzkästchen 2 „Datenschutz“ voraus.
          </li>
          <br>
          <li>Die Einwilligung zur Weitergabe seiner Daten an Dritte kann vom Teilnehmer jederzeit widerrufen werden.
          </li>
        </ol>

        <h6 class="pt-2 mb-1 fw-bold odpurple">Anwendbares Recht</h6>
        <p class="small odpurple">
          Fragen oder Beanstandungen im Zusammenhang mit dem Gewinnspiel sind an den Betreiber zu richten.
          Kontaktmöglichkeiten finden sich im Impressumsbereich der Internetseite www.odWeb.tv.
          Das Gewinnspiel des Betreibers unterliegt ausschließlich dem Recht der Bundesrepublik Deutschland. Der
          Rechtsweg ist ausgeschlossen.
        </p>

        <h6 class="pt-2 mb-1 fw-bold odpurple">Salvatorische Klausel</h6>
        <p class="small odpurple">
          Sollte eine Bestimmung dieser Teilnahmebedingungen ganz oder teilweise unwirksam sein oder werden, so wird
          dadurch die Gültigkeit dieser Teilnahmebedingungen im Übrigen nicht berührt. Statt der unwirksamen Bestimmung
          gilt diejenige gesetzlich zulässige Regelung, die dem in der unwirksamen Bestimmung zum Ausdruck gekommenen
          Sinn und Zweck wirtschaftlich am nächsten kommt. Entsprechendes gilt für den Fall des Vorliegens einer
          Regelungslücke in diesen Teilnahmebedingungen.<br>
          Viel Glück und Erfolg.
        </p>


      </div>
      <div @click="setCondition" class="align-items-center conditions d-flex justify-content-center mt-4">
        <span>Zurück</span>
      </div>
    </template>
  </div>

</template>
<style>
.vue-form-wizard {
  display: flex;
  max-width: 414px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.vue-form-wizard .wizard-header {
  display: none !important;
}

.wizard-progress-with-circle {
  display: none !important;
}

.vue-form-wizard .wizard-nav-pills {
  display: none !important;
}

.vue-form-wizard .wizard-tab-content {
  padding: 0;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.wizard-navigation {
  margin-top: 2rem !important;
}

.form-control-lg {
  font-size: 1rem;
}

.alert {
  font-weight: 500;
}

.btn-four-outline {
  border-radius: 32px !important;
  background-color: transparent !important;
  background: transparent !important;
  border: 2px solid #49992D !important;
  color: #49992D !important;
  font-weight: 500;
}
</style>
<script>
let emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
import {FormWizard, TabContent} from "vue-form-wizard";
import {WizardButton} from "vue-form-wizard/src";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
// eslint-disable-next-line no-unused-vars
import axios from "axios";

export default {
  data: function () {
    return {
      data: this.$store.state.formData,
      buttonDisable: true,
      responseApi: false,
    };
  },
  components: {
    FormWizard,
    TabContent,
    WizardButton,
  },
  methods: {
    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep;
      }
      return false;
    },
    onComplete() {
      // eslint-disable-next-line no-empty
      if (this.$store.state.formData.conditions !== false) {
      }
      axios
          .post('https://gewinnspiel.odweb.tv/api', this.$store.state.formData)
          .then(response => {
            if (response.data.status === true) {
              this.responseApi = true;
              this.$store.dispatch("clearForm");
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => this.loading = false)
    },
    // needs to dispatch an action on the store
    submit: function () {
      this.submitted = true;
    },
    validate: function (type, value) {
      if (type === "data.email") {
        this.data.email.valid = this.isEmail(value) ? true : false;
      }
    },
    isEmail: function (value) {
      return emailRegExp.test(value);
    },
    setCondition() {
      this.$store.dispatch("setCondition");
    },
  },
  mounted: function () {
    if (window.GamePlayerWon === 1) {
      // set the playerWon in the state
      this.$store.dispatch("setPlayerWon");
    }
  },
  watch: {
    $data: {
      // eslint-disable-next-line no-unused-vars
      handler: function (val, oldVal) {
        if (val.data.email.value) {
          this.validate("data.email", val.data.email.value);
        }
        if (val.data.forename === "" || val.data.lastname === "" || val.data.email.value === "" || val.data.email.valid === false || val.data.company === "" || val.data.phone === "" || val.data.conditions === false) {
          this.buttonDisable = true;
        } else {
          this.buttonDisable = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    showConditions: {
      // eslint-disable-next-line vue/return-in-computed-property
      get() {
        return this.$store.state.showConditions;
      },
      set() {
        //console.log("set the value");
      },
      fromValid: {
        // eslint-disable-next-line vue/return-in-computed-property
        get() {
          return this.$store.state.showConditions;
        },
        set() {
          //console.log("set the value");
        },
      },
    },
  },
};
</script>
