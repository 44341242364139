/* eslint-disable */
var gameField;
var board;
var currentCol;
var currentRow;
var currentPlayer;
var id;
var app;
var size;
var factor;
var diskSize;
var gameTableW;
var gameTableH;
var frame;
var frameW;
var spaceW;
var pseudogame;
var gcontainer;
var scaleX;
var clickedGame = false;
var playerWon = false;
var counter = 0;
var skipButton;

export default newgame;


function setup() {
    gameField = new Array();
    pseudogame = document.getElementById('pseudo-game');
    board = document.getElementById("game-table");
    frame = document.getElementById("frame");
    gcontainer = document.getElementById("game-container");
    app = document.getElementById("app").offsetWidth;
    frame.classList.remove("img-fluid");
    skipButton = document.getElementById("skipbtn");
    size = 48;
    factor = 1;
    diskSize = 38;
    gameTableW = 347;
    gameTableH = 300;
    spaceW = 14;
    id = 1;
    window.GamePlayerWon = 0;
}

function newgame(){
    setup();
    prepareField();
    pseudog();
    placeDisc(2);
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getRandomNumberBetween(min,max){
    return Math.floor(Math.random()*(max-min+1)+min);
}

function checkForVictory(row,col){
    if(getAdj(row,col,0,1)+getAdj(row,col,0,-1) > 2){
        return true;
    } else {
        if(getAdj(row,col,1,0) > 2){
            return true;
        } else {
            if(getAdj(row,col,-1,1)+getAdj(row,col,1,-1) > 2){
                return true;
            } else {
                if(getAdj(row,col,1,1)+getAdj(row,col,-1,-1) > 2){
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
}

function getAdj(row,col,row_inc,col_inc){
    if(cellVal(row,col) == cellVal(row+row_inc,col+col_inc)){
        return 1+getAdj(row+row_inc,col+col_inc,row_inc,col_inc);
    } else {
        return 0;
    }
}

function cellVal(row,col){
    if(gameField[row] == undefined || gameField[row][col] == undefined){
        return -1;
    } else {
        return gameField[row][col];
    }
}

function firstFreeRow(col,player){
    for(var i = 0; i<6; i++){
        if(gameField[i][col]!=0){
            break;
        }
    }
    gameField[i-1][col] = player;
    return i-1;
}

function possibleColumns(){
    var moves_array = new Array();
    for(var i=0; i<7; i++){
        if(gameField[0][i] == 0){
            moves_array.push(i);
        }
    }
    return moves_array;
}

function think(){
    var possibleMoves = possibleColumns();
    var aiMoves = new Array();
    var blocked;
    var bestBlocked = 0;

    for(var i=0; i<possibleMoves.length; i++){
        for(var j=0; j<6; j++){
            if(gameField[j][possibleMoves[i]] != 0){
                break;
            }
        }

        gameField[j-1][possibleMoves[i]] = 1;
        blocked = getAdj(j-1,possibleMoves[i],0,1)+getAdj(j-1,possibleMoves[i],0,-1);
        blocked = Math.max(blocked,getAdj(j-1,possibleMoves[i],1,0));
        blocked = Math.max(blocked,getAdj(j-1,possibleMoves[i],-1,1));
        blocked = Math.max(blocked,getAdj(j-1,possibleMoves[i],1,1)+getAdj(j-1, possibleMoves[i],-1,-1));

        if(blocked >= bestBlocked){
            if(blocked>bestBlocked){
                bestBlocked = blocked;
                aiMoves = new Array();
            }
            aiMoves.push(possibleMoves[i]);
        }
        gameField[j-1][possibleMoves[i]] = 0;
    }

    return aiMoves;
}

function Disc(player){
    this.player = player;
    this.color = player == 1 ? 'green' : 'red';
    this.id = id.toString();
    id++;

    this.addToScene = async function () {
        if(playerWon !== true){
        board.innerHTML += '<div id="d' + this.id + '" class="disc ' + this.color + '"></div>';
        if (currentPlayer == 2) {
            var possibleMoves = think();
            var cpuMove = Math.floor(Math.random() * possibleMoves.length);
            currentCol = possibleMoves[cpuMove];
            document.getElementById('d' + this.id).style.left = (spaceW + (size * factor) * currentCol) + "px";
            // denkzeit von rot
            await sleep(getRandomNumberBetween(800,1100));
            dropDisc(this.id, currentPlayer);
        }}
    }

    var $this = this;


    var _gameTable = document.getElementById('game-table');


    _gameTable.onmousemove = function(evt){
        if(currentPlayer == 1){
            currentCol = Math.floor((evt.clientX - board.offsetLeft)/(size*factor));
            if(currentCol<0){currentCol=0;}
            if(currentCol>6){currentCol=6;}
            document.getElementById('d'+$this.id).style.left = (spaceW+(size*factor)*currentCol)+"px";
            document.getElementById('d'+$this.id).style.top = "-55px";
        }
    }

    document.onload = function(evt){
        if(currentPlayer == 1){
            currentCol = Math.floor((evt.clientX - board.offsetLeft)/(size*factor));
            if(currentCol<0){currentCol=0;}
            if(currentCol>6){currentCol=6;}
            document.getElementById('d'+$this.id).style.left = (spaceW+(size*factor)*currentCol)+"px";
            document.getElementById('d'+$this.id).style.top = "-55px";
        }
    }

    // eslint-disable-next-line no-unused-vars
    _gameTable.onclick = function(evt){
        if(currentPlayer == 1){
            if(possibleColumns().indexOf(currentCol) != -1){
                dropDisc($this.id,$this.player);
            }
        }
    }

}


function pseudog() {

        pseudogame.addEventListener('click', function(e) {
            if(clickedGame === false){
                clickedGame = true;
                var evt = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                    clientY: e.pageY,
                    clientX: e.pageX,
                });
                var _gameTable = document.getElementById('game-table');
                // If cancelled, don't dispatch our event
                // eslint-disable-next-line no-unused-vars
                var canceled = !_gameTable.dispatchEvent(evt);

                var timeleft = 1;
                var downloadTimer = setInterval(function(){
                    timeleft -= 1;
                    if(timeleft <= 0){
                        clearInterval(downloadTimer);
                        clickedGame = false
                    }
                }, getRandomNumberBetween(1100,1200));
            }

        });
        pseudogame.addEventListener('mousemove', function(e) {
            var evt = new MouseEvent('mousemove', {
                bubbles: true,
                cancelable: true,
                view: window,
                clientY: e.pageY,
                clientX: e.pageX,
            });
            var _gameTable = document.getElementById('game-table');
            // If cancelled, don't dispatch our event
            // eslint-disable-next-line no-unused-vars
            var canceled = !_gameTable.dispatchEvent(evt);
        });

}


function dropDisc(cid,player){
    if(playerWon !== true){
    currentRow = firstFreeRow(currentCol,player);
    moveit(cid,(spaceW+currentRow*(size*factor)));

    currentPlayer = player;
    checkForMoveVictory();
    }

}

function checkForMoveVictory(){
    if(!checkForVictory(currentRow,currentCol)){
            placeDisc(3-currentPlayer);
    } else {
        var ww = currentPlayer == 2 ? 'Computer' : 'Player';


        placeDisc(3-currentPlayer);

        if(ww == 'Player'){
            playerWon = true;
            alert("Herzlichen Glückwunsch! Du hast deine Gewinnchance beim odWeb.tv-Gewinnspiel verdoppelt. Viel Glück!");
        } else{
            alert("Schade, leider nicht gewonnen! Aber beim odWeb.tv-Gewinnspiel hast du natürlich trotzdem noch alle Chancen. Viel Glück!");
        }

        if(playerWon === true){
            window.GamePlayerWon = 1;
        }

        // prevent user from clicking after winning

        preventUserClicks();

        setTimeout(function() {
            skipButton.click();
        }, 2000);

    }
}
function preventUserClicks() {
    pseudogame.style.pointerEvents = "none";
    board.style.pointerEvents = "none";
    frame.style.pointerEvents = "none";
    gcontainer.style.pointerEvents = "none";
}

function placeDisc(player){
    if(counter >= 42) {
        preventUserClicks();
        alert("Schade, leider nicht gewonnen! Aber beim odWeb.tv-Gewinnspiel hast du natürlich trotzdem noch alle Chancen. Viel Glück!");
        setTimeout(function() {
            skipButton.click();
        }, 2000);
    }
    if(playerWon !== true){
        currentPlayer = player;
        var disc = new Disc(player);
        disc.addToScene();
    }
    counter = counter + 1;
}

function prepareField(){
    gameField = new Array();
    for(var i=0; i<6; i++){
        gameField[i] = new Array();
        for(var j=0; j<7; j++){
            gameField[i].push(0);
        }
    }
}

function moveit(who,where){

    try {
        document.getElementById('d'+who).style.top = (where)+'px';
        document.getElementById('d'+who).style.width = diskSize+'px';
        document.getElementById('d'+who).style.height = diskSize+'px';
    } catch(e) {

    }

}