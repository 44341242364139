import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import VueRouter from 'vue-router'
import Register from "@/components/Register";
import Game from "@/components/Game";


import 'es6-promise/auto'

Vue.use(VueRouter)
Vue.use(Vuex)

const routes = [
  { path: '/', component: Game},
  { path: '/register', component: Register },
  { path: '/game', component: Game },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

const store = new Vuex.Store({
  state: {
    gameCreatedState: false,
    formData: {
      forename: "",
      lastname: "",
      email: {
        value: "",
        valid: false
      },
      company: "",
      phone: "",
      submitted: false,
      conditions: false,
      playerWon: false,
    },
    gamebase: "",
    showConditions: false,

    gameStarted: false,
    gameSkipped: false,
  },
  mutations: {
    createGame (state) {
      state.gameCreatedState = true;
    },
    setGameState (state, html) {
      state.gamebase = html;
    },
    setCondition (state) {
      state.showConditions = !state.showConditions;
    },
    clearForm (state) {
      state.formData.forename = "",
      state.formData.lastname =  "",
      state.formData.email.value =  "",
      state.formData.email.valid = false;
      state.formData.company = "",
      state.formData.phone =  "",
      state.formData.submitted = false,
      state.formData.conditions = true
    },
    setPlayerWon (state) {
      state.formData.playerWon = true;
    }
  },
  actions: {
    createGame (context) {
      context.commit('createGame');
    },
    setGameState (context, html) {
      context.commit('setGameState', html);
    },
    setCondition (context) {
      context.commit('setCondition');
    },
    clearForm (context) {
      context.commit('clearForm');
    },
    setPlayerWon (context){
      context.commit('setPlayerWon');
    }
  }
})


new Vue({
  render: h => h(App),
  el: '#app',
  template: '<App/>',
  components: { App },
  router: router,
  store: store,
}).$mount('#app');



