<template>
  <div style="height: 100%; min-height: 100vh;  background-image: linear-gradient(0deg, #FFFFFF 0%, #D9C7F2 100%);">
    <template v-if="!showConditions">
      <div class="d-flex flex-column align-items-center justify-content-center pt-4">
        <img src="../assets/img/logo.svg" style="max-width: 180px">
        <p class="mt-3 text-center fw-bold px-3" style="color:#341367;">Gewinne und verdopple deine Gewinnchancen</p>
        <img src="../assets/img/player-computer.svg" class="mt-1">
      </div>


      <div class="position-relative">
        <div id="game-container" class="d-flex justify-content-center mt-4 position-relative"
             style="margin-bottom: 3.8rem">
          <div id="game-base">
            <div id="game-table"></div>
          </div>
          <img id="frame" class="position-absolute" src="../assets/img/frame.svg"
               style="pointer-events: none;margin-top: -7px;margin-left: 2px;">
        </div>
        <div @click="setGameState" id="pseudo-game" class="position-absolute mx-auto"
             style="padding-left:4px;"></div>
      </div>

      <div class="align-items-center d-flex flex-column justify-content-center mb-4">
        <button :disabled="gameStatus" @click="createGame" type="button" class="btn btn-four px-5">Spiel starten</button>
        <router-link id="skipbtn" class="mt-2 fw-bold" style="color:#341367;text-decoration: none" to="/register">Spiel überspringen</router-link>
      </div>
    </template>

    <template v-else>
      <div class="px-4">
        <h3 class="pt-4 mb-3 fw-bold odpurple">Teilnahmebedinungen</h3>

        <p class="small odpurple">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
          ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
          magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
          kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
          sanctus est Lorem ipsum dolor sit amet.
          Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu
          feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril
          delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
          sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
          Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
          commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat,
          vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit
          praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.

          Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat
          facer</p>

      </div>
      <div @click="setCondition" class="align-items-center conditions d-flex justify-content-center mt-4">
        <span>Zurück</span>
      </div>
    </template>


  </div>


</template>
<script>
import newgame from "@/assets/js/game";
export default {
  name: 'Main',
  data() {
    return {
    }
  },
  methods: {
    createGame() {
      // calls the createGame Action in the store
      if (this.gameStatus === false) {
        this.$store.dispatch('createGame');
        newgame();
      }
    },
    setCondition() {
      this.$store.dispatch('setCondition');
    },
    setGameState() {
      let html = document.getElementById("game-table").innerHTML;
      this.$store.dispatch('setGameState', html);
    }
  },
  mounted() {
    var gcontainer = document.getElementById("game-container");
    var pseudogame = document.getElementById('pseudo-game');
    var app = document.getElementById("app").offsetWidth;
    var scaleX = (app * 100 / 414) / 100;
    var board = document.getElementById("game-table");

    pseudogame.style.width = ((board.offsetWidth)) + 'px';
    pseudogame.style.height = ((board.offsetHeight)) + 'px';

    if (app < 414) {
      gcontainer.style.pointerEvents = "none";
      gcontainer.style.transform = "scale(" + scaleX + ")";
      pseudogame.style.width = ((board.offsetWidth) * scaleX - 10) + 'px';
      pseudogame.style.height = ((board.offsetHeight) * scaleX) + 'px';
    }
    if (app < 321) {
      gcontainer.style.pointerEvents = "none";
      gcontainer.style.transform = "scale(" + scaleX + ")";
      pseudogame.style.width = ((board.offsetWidth) * scaleX) + 'px';
      pseudogame.style.height = ((board.offsetHeight) * scaleX) + 'px';
    }
    if(this.gameStatus === true) {
      board.innerHTML = this.$store.state.gamebase;
    }

  },
  beforeDestroy() {
  },
  computed: {
    gameStatus: {
      // eslint-disable-next-line vue/return-in-computed-property
      get() {
        return this.$store.state.gameCreatedState;
      },
      set() {
        //console.log("set the value");
      }
    },
    showConditions: {
      // eslint-disable-next-line vue/return-in-computed-property
      get() {
        return this.$store.state.showConditions;
      },
      set() {
        //console.log("set the value");
      }
    },
  }
}
</script>
<style>

.conditions {
  height: 50px;
  width: 100%;
  background-color: #654C98;
  color: #fff;
  font-size: .9rem;
  font-weight: bold;
}

#pseudo-game {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.odpurple {
  color: #341367;
}

#frame {
  width: 409px;
  padding-right: 0.9rem !important;
  padding-left: 1.1rem !important;
}

.btn-four {
  background-image: linear-gradient(180deg, #59BA36 0%, #3E8226 100%) !important;
  border-radius: 32px !important;
  color: #fff !important;
  font-weight: 500 !important;
  border: 1px solid white !important;
}

#game-base {
  margin: 0 auto;
  background-size: cover;
}

#game-table {
  margin: 0 auto;
  position: relative;
  width: 347px;
  height: 300px;
}

.disc {
  position: absolute;
  /*z-index:-1;*/
  top: -55px;
  left: 14px;
  border-radius: 9999px;
  transition: all .3s;
}

.disc.green {
  background: #59BA36;
  background-image: url("../assets/img/smile_very_good.svg");
}

.disc:last-of-type {
  background-color: transparent;
  background-image: none;
}

.disc.red {
  background: #E84433;
  background-image: url("../assets/img/smile_very_bad.svg");
}
</style>
